import React from 'react';
import { GET_SKILLS } from '../../../apis/constants.api';
import { getSkills } from '../../../apis/skills.api';
import InfiniteSelect from '../../shared/InfiniteSelect';

const RelevantSkill = ({ field }) => {
  return (
    <InfiniteSelect
      queryKey={GET_SKILLS}
      queryFn={getSkills}
      value={field?.value?.map((skill) => ({
        value: skill.value,
        label: skill.label,
      }))}
      onChange={(newValue) => {
        field.onChange(newValue);
      }}
      onSuccess={(data, setState) => {
        setState(data?.data?.data?.map?.(item => ({ value: item._id, label: item.name })));
      }}
      selectProps={{
        isMulti: true,
        placeholder: "Select your relevant skills",
        className: "menu-skill",
        menuPlacement: 'top',
      }}
    />
  );
};

export default RelevantSkill;
