import { Box, Image, Text } from "@chakra-ui/react";
import { last } from "lodash";
import { memo, useMemo } from "react";
import FileViewer from 'react-file-viewer';
import { read, utils } from 'xlsx';

const MessageFileItemPreview = ({ file, openDocViewer }) => {
  const type = last(file.split("."));
  const name = useMemo(() => {
    const filePath = last(file.split('/'));
    const splittedByHyphen = filePath.split('-');
    splittedByHyphen.pop();
    splittedByHyphen.pop();

    return splittedByHyphen.join("-") + "." + type;
  }, [file, type]);

  const imageTypes = [
    'jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'ico',
    'heic', 'heif', 'raw', 'cr2', 'nef', 'arw', 'dng', 'avif'
  ];

  const docTypes = [
    'pdf'
  ];

  const videoTypes = [
    'mp4', 'mov', 'wmv', 'flv', 'webm', 'mkv', 'm4v', '3gp',
    'mpg', 'ogv', 'ts', 'vob'
  ];

  const audioTypes = [
    'mp3', 'wav', 'ogg', 'aac', 'flac', 'm4a', 'wma', 'alac',
    'mid', 'midi', 'amr', 'mpeg',
  ];

  const downloadTypes = ["zip", "avi", "svg", "rtf", 'xls', 'xlsx', 'key', 'csv', 'txt', 'doc', 'docx', 'ppt', 'pptx',
    'odt', 'ods', 'odp', 'pages', 'numbers', 'md', 'sheet', 'aiff',
  ];

  // console.log(' !!!!!!!!!!!!! Called here ');

  // const handleDownloadZIP = () => {
  //   var zip = new JSZip();
  //   zip.file("Hello.txt", "Hello world\n");
  //   jQuery("#blob").on("click", function () {
  //     zip.generateAsync({ type: "blob" }).then(function (blob) { // 1) generate the zip file
  //       saveAs(blob, "hello.zip");                          // 2) trigger the download
  //     }, function (err) {
  //       jQuery("#blob").text(err);
  //     });
  //   });
  // };

  const renderPreview = () => {
    if (imageTypes.includes(type)) {
      return <Image src={file} alt={file} h={'20rem'} w={'20rem'} objectFit={'cover'} />;
    } else if (videoTypes.includes(type)) {
      return (
        <video controls src={file} className="w-full h-32">
          <source src={file} type={"video/" + type} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (audioTypes.includes(type)) {
      return (
        <audio controls>
          <source src={file} type={"audio/" + type} />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (docTypes.includes(type)) {
      return (
        <iframe src={file}>
        </iframe>
      );
    } else if (downloadTypes.includes(type)) {
      return (
        <div onClick={() => handleDownload(file)}>
          {`Download ${type} File`}
        </div>
      );
    } else {
      return (
        <div className="">
          <p>Can not show preview the file</p>
          <p>{file}</p>
        </div>
      );
    }
  };


  // const handleDownload = (fileUrl) => {
  //   const link = document.createElement('a');
  //   link.href = fileUrl;
  //   link.setAttribute('download', 'image.svg');
  //   document.body.appendChild(link);
  //   return;
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl, { mode: 'cors' });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const filenameSplit = file.split(".");
      const popLastEle = filenameSplit.pop();
      const filename = filenameSplit.join(".");

      if (type === 'zip') {
        link.setAttribute('download', `${filename}.zip`);
      } else if (type === 'svg%2Bxml' || type === 'svg') {
        link.setAttribute('download', `${filename}.svg`);
      }
      else if (type === 'avi') {
        link.setAttribute('download', `${filename}.avi`);
      }
      else {
        link.setAttribute('download', file);
      }

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the SVG:', error);
    }
  };

  const handleOnClick = () => {
    if (audioTypes.includes(type) || downloadTypes.includes(type)) {
    } else {
      openDocViewer();
    }
  };

  return (
    <div className="message-file-item-preview">
      <Text fontSize={'fs.12'} color="gray.500">{name}</Text>
      <Box onClick={handleOnClick} p={3} cursor={'pointer'}>
        {renderPreview()}
      </Box>
    </div>
  );
};

export default memo(MessageFileItemPreview);