import { Avatar, AvatarGroup, Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import EditTaskDialog from "../../BoardListview/EditTaskDialog";

const KanbanItem = ({
  index,
  parent,
  item,

}) => {

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.title,
    data: {
      index,
      parent,
      id: item._id
    },
  });
  const [daysLeft, setDaysLeft] = useState(0);
  const [extractedUrl, setExtractedUrl] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  useEffect(() => {
    const calculateDaysLeft = () => {
      const today = moment();
      const due = moment(item.dueDate);
      const difference = due.diff(today, 'days');
      setDaysLeft(difference);
    };

    calculateDaysLeft();
  }, [item.dueDate]);

  const handleEditClick = (event) => {
    onOpen();
  };


  const extractData = () => {
    const urlRegex = /<url>(.*?)<url>/;
    const urlMatch = item.plainDescription.match(urlRegex);
    const url = urlMatch ? urlMatch[1] : '';
    const text = item.plainDescription.replace(urlRegex, '').trim();

    setExtractedUrl(url);
    setExtractedText(text);
  };
  useEffect(() => {
    if (item.plainDescription) {
      extractData();
    }
  }, [item.plainDescription]);

  return (
    <>
      <Flex
        background="linear-gradient(0deg, #FFFFFF, #FFFFFF)"
        border="1px solid rgba(160, 166, 177, 0.2)"
        borderRadius="6px"
        mb="15px"
        p="15px"
        flexDirection="column"
        {...listeners}
        {...attributes}
        transform={style.transform}
        ref={setNodeRef}
        onClick={handleEditClick}
      >

        <Image maxW="355px" maxH="200px" borderRadius="6px" mb="5px" src={extractedUrl} />
        <Text mb="13px" color="rgba(32, 43, 60, 1)" fontSize="fs.18" fontWeight="fw.500">{item.title}</Text>
        <Text mb="8px" color="rgba(134, 139, 148, 1)" fontSize="fs.14" fontWeight="fw.400" >{item.key}</Text>
        <Text mb="8px" color="rgba(134, 139, 148, 1)" fontSize="fs.14" fontWeight="fw.400" textOverflow="ellipsis" className='ellipsis' overflow="hidden" display="-webkit-box" >{extractedText}</Text>

        <Box mb="10px" border="1px solid rgba(160, 166, 177, 0.2)"></Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <AvatarGroup size='xs' max={2}>
              <Avatar name={item?.assignee?.name} src={item?.assignee?.profilePicture} />
              <Avatar name={item?.reporter?.name} src={item?.reporter?.profilePicture} />
            </AvatarGroup>
          </Flex>
          <Flex gap="15px">

            <Flex gap="8px" alignItems="center">
              <FontAwesomeIcon color='rgba(134, 139, 148, 1)' icon={faClock} />
              <Text color="rgba(134, 139, 148, 1)" fontSize="fs.14" fontWeight="fw.400">{`${daysLeft ? daysLeft : 0} Day Left`}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex >

      {isOpen && <EditTaskDialog isOpen={isOpen} onClose={onClose} item={item} />}
    </>
  );
};


export default KanbanItem;