import {
  Box, Button, Flex, Grid, GridItem, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Spinner, Text
} from '@chakra-ui/react';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { GET_ALL_USERS, GET_TASKS } from '../../../apis/constants.api';
import { updateTask } from '../../../apis/task.api';
import { getPlatformUserDataAPI } from '../../../apis/users.api';
import { AppContext } from '../../../contexts/AppContext';
import { SlateInputField } from '../../RichTextBox/SlateInputField';
import AddComments from '../AddComments';
import { PriorityOptions, StatusOptions } from '../Contants';
import { updateSchema } from '../form.schema';


export default function EditTaskDialog(props) {
  const { isOpen, onClose, item } = props;
  const [isGridOpen, setIsGridOpen] = useState(true);
  const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const [isEditData, setIsEditData] = useState({
    assignee: false,
    priority: false,
    dueDate: false,
    title: false,

  });


  const [stateApp] = useContext(AppContext);
  const toggleGrid = () => {
    setIsGridOpen(!isGridOpen);

  };
  const { data: users } = useQuery({
    queryKey: [GET_ALL_USERS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE
    }],
    queryFn: getPlatformUserDataAPI,
  });

  const usersList = users?.data?.data || [];
  const UserOptions = usersList.filter((e) => e.role === "Student" || stateApp.user._id === e._id).map((e) => ({ label: e.name, value: e._id }));

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      title: item.title,
      description: item.description,
      comments: item.comments,
      priority: PriorityOptions.find((e) => e.value === item.priority),
      status: StatusOptions.find((e) => e.value === item.status),
      assignee: UserOptions.find((e) => e.value === item.assignee._id),
      dueDate: item.dueDate,
      reporter: { label: stateApp?.user?.name, value: stateApp?.user?._id }
    },
    resolver: yupResolver(updateSchema),
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 'none',
      boxShadow: 'none',
      background: "#F7FAFC"
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  const { mutate, isLoading } = useMutation(updateTask, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_TASKS] });
      onClose();
      reset();
    },
  });

  const onSubmit = async (data) => {
    const payload = {
      title: data?.title,
      description: data?.description,
      priority: data?.priority?.value,
      status: data?.status?.value,
      assignee: data?.assignee?.value,
      dueDate: data?.dueDate,
      reporter: data?.reporter?.value,
      plainDescription: data?.plainDescription
    };

    const taskId = item._id;
    await mutate({ taskId, ...payload });

  };
  const assignToMe = () => {
    if (stateApp?.user?._id)
      setValue('assignee', { label: stateApp?.user?.name, value: stateApp?.user?._id });
  };

  const initialValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];


  return (
    <>
      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent minWidth={{ base: "", md: "750px", lg: "900px", xl: "1100px" }}>
            <ModalBody p="0" >
              <Flex flexWrap={{ base: "wrap", md: "nowrap" }}>
                <Box width="655px" p="25px" borderRight="1px solid #E5E5E5">
                  <Text mb="5px" ml="4px" color="rgba(134, 139, 148, 1)" fontSize="fs.14" fontWeight="fw.400" >{item.key}</Text>
                  <Flex gap="10px" justifyContent="start" alignItems="center">
                    <Box width={"100%"}>
                      <Controller
                        name="title"
                        control={control}
                        render={({ field, fieldState }) =>
                          <>
                            <Input
                              {...field}
                              placeholder="Enter your title"
                              // _hover={{ borderColor: "#713EDD" }}
                              errorBorderColor="red.500"
                              isInvalid={errors?.title?.message || fieldState?.['title']?.message}
                              borderRadius={"8px"}
                              size={"md"}
                              maxHeight={"50px"}
                              _focusVisible={{ borderColor: "#713EDD" }}
                              onFocus={(e) => e.currentTarget.style.border = '2px solid #4732b8'}

                              onBlur={(e) => e.currentTarget.style.border = true && 'none'}
                              style={{ border: field.value && "none", fontWeight: "600", padding: "0px 3px", fontSize: "20px" }}

                            />

                            {(errors?.title?.message || fieldState?.['title']?.message)
                              && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.title?.message || fieldState?.['title']?.message}</Text>}
                          </>
                        }
                      />
                    </Box>
                  </Flex>

                  <Box mt="15px">
                    <Flex mb="10px" justifyContent="space-between">
                      <Text fontSize="fs.16" fontWeight="fw.500">Description</Text>
                    </Flex>

                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) =>
                        <SlateInputField
                          placeholder="Enter Description"
                          initValue={item.description ? JSON.parse(item.description) : initialValue}
                          textContainerStyle={{ minHeight: 280, margin: 10, padding: "0px 13px" }}
                          onChange={field.onChange}
                          setValue={setValue}
                          name="plainDescription"

                        />
                      }
                    />
                  </Box>
                </Box>

                <Box width="445px" p="25px">
                  <Flex justifyContent="end" alignItems="center" gap="20px">

                    <Flex justifyContent="center" cursor="pointer" _hover={{ bgColor: "#F7F7F7" }} onClick={onClose} alignItems="center" width="30px" height="30px" border="1px solid #868B944D" borderRadius="6px">
                      <FontAwesomeIcon color='rgba(134, 139, 148, 1)' icon={faXmark} />
                    </Flex>
                  </Flex>

                  <Box mt="15px" >
                    <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Status</Text>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) =>
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={field.value}
                          isSearchable={true}
                          options={StatusOptions}
                          onChange={(value) => field.onChange(value)}
                        />
                      }
                    />
                  </Box>

                  <Box border="1px solid #A0A6B133" borderRadius="6px" mt="15px">
                    <Flex onClick={toggleGrid} cursor="pointer" justifyContent="space-between" alignItems="center" p="12px" borderBottom="1px solid #A0A6B133">
                      <Text>Details</Text>
                      {isGridOpen ? <FontAwesomeIcon icon={faChevronUp} /> :
                        <FontAwesomeIcon icon={faChevronDown} />}
                    </Flex>
                    {isGridOpen && (
                      <Grid templateColumns="repeat(1, 1fr)" >
                        <Grid templateColumns="repeat(2, 1fr)" >
                          <GridItem rowSpan={1} colSpan={1} pt={5} pl={3}>
                            <Text fontSize="fs.16" fontWeight="fw.400">Assignee</Text>
                          </GridItem>
                          <GridItem rowSpan={1} colSpan={1} padding={"14px 12px"}>
                            <Flex alignItems="center" gap="8px">
                              <Box width={"235px"} >
                                <Controller
                                  name="assignee"
                                  control={control}
                                  render={({ field, fieldState }) => {
                                    return (
                                      <>
                                        <ReactSelect
                                          className="basic-single"
                                          classNamePrefix="select"

                                          isSearchable={true}
                                          defaultValue={field.value}
                                          options={UserOptions}
                                          onChange={(value) => field.onChange(value)}
                                          onFocus={() => setIsEditData({ assignee: true })}
                                          onBlur={() => setIsEditData({ assignee: false })}
                                          styles={(!isEditData.assignee) && customStyles}

                                        />
                                        {(errors?.assignee?.message || fieldState?.['assignee']?.message)
                                          && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.assignee?.message || fieldState?.['assignee']?.message}</Text>}
                                      </>
                                    );
                                  }}
                                />
                                <Text fontSize="fs.14" fontWeight="fw.500" mb="8px" mt="6px" ml="6px" color="#4732b8" cursor={"pointer"} onClick={() => assignToMe()}>Assign to me</Text>
                              </Box>
                            </Flex>

                          </GridItem>
                        </Grid>
                        <Grid templateColumns="repeat(2, 1fr)" >
                          <GridItem rowSpan={1} colSpan={1} pt={1} pb={5} pl={3}>
                            <Text fontSize="fs.16" fontWeight="fw.400">Reporter</Text>
                          </GridItem>
                          <GridItem rowSpan={1} colSpan={1} p={1} padding={"0px 12px"}>
                            <Flex alignItems="center" gap="8px">
                              <Box width={"235px"} >
                                <Controller
                                  name="reporter"
                                  control={control}
                                  render={({ field }) =>
                                    <ReactSelect
                                      isDisabled
                                      value={field.value}
                                      styles={customStyles}
                                    />
                                  }
                                />
                              </Box>

                            </Flex>
                          </GridItem>
                        </Grid>
                        <Grid templateColumns="repeat(2, 1fr)" >
                          <GridItem rowSpan={1} colSpan={1} pt={5} pb={5} pl={3}>
                            <Text fontSize="fs.16" fontWeight="fw.400">Priority</Text>
                          </GridItem>
                          <GridItem rowSpan={1} colSpan={1} p={3}>
                            <Flex alignItems="start" gap="8px">

                              <Box width={"235px"} >
                                <Controller
                                  name="priority"
                                  control={control}
                                  render={({ field }) =>
                                    <ReactSelect
                                      className="basic-single"
                                      classNamePrefix="select"
                                      defaultValue={field.value}
                                      isSearchable={true}
                                      options={PriorityOptions}
                                      onChange={(value) => field.onChange(value)}
                                      onFocus={() => setIsEditData({ priority: true })}
                                      onBlur={() => setIsEditData({ priority: false })}
                                      styles={(!isEditData.priority) && customStyles}
                                    />
                                  }
                                />
                              </Box>

                            </Flex>
                          </GridItem>
                        </Grid>

                        <Grid templateColumns="repeat(2, 1fr)" >
                          <GridItem rowSpan={1} colSpan={1} p={3} pt={5} pb={5} pl={3}>
                            <Text fontSize="fs.16" fontWeight="fw.400">Due date</Text>
                          </GridItem>
                          <GridItem rowSpan={1} colSpan={1} p={3} >
                            <Flex alignItems="center" gap="8px">
                              <Controller
                                name="dueDate"
                                control={control}
                                render={({ field }) =>
                                  <>
                                    <Input
                                      {...field}
                                      placeholder="Select Date and Time"
                                      size="sm"
                                      type="date"
                                      height="38px"
                                      width={"auto"}
                                      value={field.value ? new Date(field.value).toISOString().slice(0, 16) : ''}
                                      bg="#F7FAFC"
                                      borderRadius="8px"
                                      _focusVisible={{ borderColor: "#713EDD" }}
                                      _focus={{ border: "2px solid #4732b8" }}
                                      style={{ border: field.value && "none" }}
                                    />
                                  </>

                                }
                              />
                            </Flex>
                          </GridItem>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Flex>

              <AddComments isOpen={isOpen} stateApp={stateApp} initialValue={initialValue} id={item._id} />
            </ModalBody>
            <ModalFooter background="#F5F4F6" borderRadius=" 0px 0px 10px 10px">
              <Flex gap="10px">
                <Button background="#EAE9EC" onClick={() => { onClose(); reset(); }}>Cancel</Button>
                <Button type="submit" px={{ md: "15px", lg: "30px" }} py="8px"
                  background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                  gap={5}
                  _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">Update {isLoading && <Spinner />}</Button>
              </Flex>
            </ModalFooter>
          </ModalContent >
        </form>


      </Modal >
    </>
  );
}