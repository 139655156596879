import {
  Box, Button, Flex, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text,
  useToast
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { GET_ALL_USERS, GET_PROJECTS } from "../../../apis/constants.api";
import { createProjectAPI } from "../../../apis/project.api";
import { getPlatformUserDataAPI } from "../../../apis/users.api";
import useDebounce from "../../../hooks/useDebounce.hook";
import CustomDropzone from "../../Dropzone/FileDropZone";

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
const CreateDialog = ({ onClose, isOpen, control, handleSubmit, errors }) => {
  const toast = useToast();
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const [userOptions, setUserOptions] = useState([]);

  const [inputSearch, setInputSearch] = useState("");
  const debouncedSearch = useDebounce(inputSearch);

  const { mutate } = useMutation(createProjectAPI, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_PROJECTS] });
      onClose();

    },
    onError: (error) => {
      let errorMessage;
      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;

        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }

    }
  });
  const { isLoading, data: userData } = useQuery({
    queryKey: [GET_ALL_USERS, {
      search: debouncedSearch, skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE
    }],
    queryFn: getPlatformUserDataAPI,
    select: (data) => {
      return data.data?.data?.map(user => ({ value: user?._id, label: `${user?.name} (${user?.email})`, fullObj: user }));
    }
  });

  useEffect(() => {
    if (userData) {
      setUserOptions((oldUserData) => uniqBy([...oldUserData, ...userData], 'value'));
    }
  }, [userData]);

  const onSubmit = async (data) => {
    const { name, key, icon } = data;
    if (!name || !key || !icon) {
      return;
    };
    const payload = {
      name: data.name,
      key: data.key,
      icon: data.icon,
      team: data.team,
    };
    await mutate(payload);

  };

  const incPageSize = () => setPage((oldPageSize) => oldPageSize + 1);

  return (<>
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Project</ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Box>
              <Box >
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Project name </Text>
                <Controller
                  name="name"
                  control={control}

                  rules={{ required: true }}
                  render={({ field, fieldState }) =>
                    <>  <Input {...field} placeholder="Project name "
                      errorBorderColor="red.500"
                      isInvalid={errors?.name?.message || fieldState?.['name']?.message}
                      height="50px" _hover={{ borderColor: "#713EDD" }}
                      background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />

                      {(errors?.name?.message || fieldState?.['name']?.message)
                        && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.name?.message || fieldState?.['name']?.message}</Text>}
                    </>
                  }
                />
              </Box>
              <Box mt="20px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Key</Text>
                <Controller
                  name="key"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) =>
                    <>
                      <Input errorBorderColor="red.500"
                        isInvalid={errors?.key?.message || fieldState?.['key']?.message}
                        {...field} placeholder="Project key " height="50px" _hover={{ borderColor: "#713EDD" }} background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />

                      {(errors?.key?.message || fieldState?.['key']?.message)
                        && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.key?.message || fieldState?.['key']?.message}</Text>}
                    </>
                  }
                />
              </Box>
              <Box mt="20px">
                <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} mb="8px" fontWeight="fw.500">Icon</Text>
                <Controller
                  name="icon"
                  control={control}
                  render={({ field, fieldState }) =>
                    <CustomDropzone
                      {...field}
                      innerMessage='Upload Project Icon'
                      acceptedFiles={{ 'image/*': ['jpg', 'jpeg', 'png', '.webp'] }}
                      maxFiles={5}
                      defaultImages={[]}
                      onFileChange={(files) => field.onChange(files[0])}
                      error={errors?.icon?.message || fieldState?.['icon']?.message}
                    />
                  }
                />
              </Box>
              <Box my="20px">
                <Box mt="20px" mb="27px">
                  <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Add Member</Text>

                  <Controller
                    name="team"
                    control={control}
                    render={({ field, fieldState }) =>
                      <>
                        <Select
                          isMulti
                          cacheOptions
                          defaultOptions
                          isLoading={isLoading}
                          options={userOptions}
                          onChange={(value) => field.onChange(value.map(val => val.value))}
                          onInputChange={setInputSearch}
                          onMenuScrollToBottom={incPageSize}
                        />
                        {(errors?.team?.message || fieldState?.['team']?.message)
                          && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.team?.message || fieldState?.['team']?.message}</Text>}
                      </>

                    }
                  />
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter background="#F5F4F6" borderRadius=" 0px 0px 10px 10px">
            <Flex gap="10px">
              <Button background="#EAE9EC" onClick={onClose}>Cancel</Button>
              <Button type="submit" px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">Save</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal></>);
};
export default CreateDialog;