import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardLayout from './component/Layouts/Dashboard.layout';
import ProtectedRoute from './component/Routes/ProtectedRoute';
import PublicRoute from './component/Routes/PublicRoute';

import EBookPage from './component/Pages/EBooks/index.jsx';
import Groups from './component/Pages/Groups/index.jsx';
import ManagersUsersPage from './component/Pages/ManagersUsers/index.jsx';
import MessagesPage from './component/Pages/Messages/index.jsx';
import ProductsPage from './component/Pages/Products/index.jsx';
import Project from './component/Pages/Project/index.jsx';
import RecoverPassword from './component/Pages/RecoverPassword/index.jsx';
import ResetEmail from './component/Pages/ResetEmail/index.jsx';
import ScreeningStatus from './component/Pages/ScreeningStatus/index.jsx';
import SetNewPassword from './component/Pages/SetPassword/index.jsx';
import SignInPage from './component/Pages/SignIn/index.jsx';
import CreateAccount from './component/Pages/SignUp/index.jsx';
import Skills from './component/Pages/Skills/index.jsx';
import TaskBoards from './component/Pages/TaskBoard/index.jsx';
import Version from './component/Pages/Version/index.jsx';
import UserProfile from './component/UserProfile/index.jsx';
import VideoCall from './component/VideoSDK/index.jsx';
import EBookView from './component/Pages/EBooks/EBookView.jsx';
import HomeContent from './component/Home/HomeContent/index.jsx';
import UserStepper from './component/Pages/Stepper/index.jsx';

const AppRoutes = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/signin" element={<PublicRoute component={SignInPage} />} />
        <Route path="/signup" element={<PublicRoute component={CreateAccount} />} />
        <Route path="/recover-password" element={<PublicRoute component={RecoverPassword} />} />
        <Route path="/reset-email" element={<PublicRoute component={ResetEmail} />} />
        <Route path="/message/*" element={<ProtectedRoute layout={DashboardLayout} component={MessagesPage} />} />
        <Route path="/set-password/:token" element={<PublicRoute component={SetNewPassword} />} />
        <Route path="/boards/*" element={<ProtectedRoute layout={DashboardLayout} component={TaskBoards} />} />
        <Route path="/product-list" element={<ProtectedRoute layout={DashboardLayout} component={ProductsPage} />} />
        <Route path="/library" element={<ProtectedRoute layout={DashboardLayout} component={EBookPage} />} />
        <Route path="/channels" element={<ProtectedRoute layout={DashboardLayout} component={Groups} />} />
        <Route path="/profile" element={<ProtectedRoute layout={DashboardLayout} component={UserProfile} />} />
        <Route path="/manage-users" element={<ProtectedRoute layout={DashboardLayout} component={ManagersUsersPage} />} />
        <Route path="/project" element={<ProtectedRoute layout={DashboardLayout} component={Project} />} />
        <Route path="/screening/:status" element={<ProtectedRoute layout={null} component={ScreeningStatus} />} />
        <Route path="/skills" element={<ProtectedRoute layout={DashboardLayout} component={Skills} />} />
        <Route path="/version" element={<Version />} />
        <Route path="/user-signup-steps" element={<UserStepper />} />
        <Route path="/ebook-view" element={<EBookView />} />
        <Route path="/call/:meetingID" element={<VideoCall />} />
        <Route path="/" element={<ProtectedRoute layout={DashboardLayout} component={HomeContent} />} />
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;