import * as yup from "yup";

const optionSchema = yup.object().shape({
  label: yup.string().required(),
  value: yup.string().required(),
}).required();

export const createSchema = yup
  .object({
    title: yup.string().required(),
    description: yup.string(),
    priority: optionSchema,
    status: optionSchema,
    assignee: optionSchema,
    project: optionSchema,
    dueDate: yup.string(),
    reporter: optionSchema
  });

export const updateSchema = yup
  .object({
    title: yup.string().required(),
    description: yup.string(),
    priority: optionSchema,
    status: optionSchema,
    assignee: optionSchema,
    dueDate: yup.string(),
    reporter: optionSchema,
    comments: yup.string()
  });

export const addCommentSchema = yup.object({
  text: yup.string(),
  plainText: yup.string()
});


