import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roomId: null,
  channelType: null,
};

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setChannel: (state, action) => {
      state.roomId = action.payload.roomId;
      state.channelType = action.payload.channelType;
    },
    clearChannel: (state) => {
      state.roomId = null;
      state.channelType = null;
    },
  },
});

export const { setChannel, clearChannel } = channelSlice.actions;

export default channelSlice.reducer;
