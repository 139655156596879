import { Flex } from '@chakra-ui/react';
import { DndContext, PointerSensor, rectIntersection, useSensor, useSensors } from '@dnd-kit/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { GET_TASKS } from '../../apis/constants.api';
import { updateTask } from '../../apis/task.api';
import KanbanLane from './KanabanLane/Lane';

const Board = ({ setLanes, lanes }) => {
    const [title, setTitle] = useState("");
    const queryClient = useQueryClient();
    const [showInput, setShowInput] = useState(false);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    );
    const handleAddLane = (title) => {
        const newLaneTitle = title;
        setLanes([...lanes, { title: newLaneTitle, items: [] }]);
    };

    const { mutate } = useMutation(updateTask, {
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: [GET_TASKS] });

        },
    });
    const onSubmit = async (id, status) => {
        const payload = {
            status: status,
        };
        const taskId = id;
        await mutate({ taskId, ...payload });
    };

    const onDragEnd = async (event) => {
        const id = event.active.data.current?.id;
        const status = event.over?.id == "To Do" ? "Todo" : event.over?.id.replace(/\s+/g, '');

        const container = event.over?.id;
        const itemIndex = event.active.data.current?.index ?? 0;
        const itemParent = event.active.data.current?.parent ?? "To Do";

        setLanes(prevLanes => {
            let movedItem;

            const updatedLanes = prevLanes.map(lane => {
                if (lane.title === itemParent) {
                    movedItem = lane.items[itemIndex];
                    const updatedItems = lane.items.filter((_, index) => index !== itemIndex);
                    return { ...lane, items: updatedItems };
                } else {
                    return lane;
                }
            });

            if (movedItem) {
                const updatedLanesWithMovedItem = updatedLanes.map(lane => {
                    if (lane.title === container) {
                        return { ...lane, items: [...lane.items, movedItem] };
                    } else {
                        return lane;
                    }
                });
                return updatedLanesWithMovedItem;
            }

            return updatedLanes;
        });
        onSubmit(id, status);
    };



    return (
        <DndContext collisionDetection={rectIntersection} onDragEnd={onDragEnd} sensors={sensors}>
            <Flex flexDirection="column" mt="30px">
                <Flex gap="26px">
                    {lanes.map(({ title, items }, index) => (
                        <KanbanLane key={index} title={title} items={items} setItems={(updatedItems) => {
                            setLanes(prevLanes => {
                                const updatedLanes = [...prevLanes];
                                updatedLanes[index].items = updatedItems;
                                return updatedLanes;
                            });
                        }} />
                    ))}
                    {/* <Flex flexDirection="column" borderTop="3px solid rgba(160, 166, 177, 0.2)" borderRadius="6px">
                        {showInput ? (
                            <Flex flexDirection="row" gap="5px" alignItems="center">
                                <Input
                                    type='text'
                                    focusBorderColor='#713EDD'
                                    variant='filled'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter task title"
                                    height="45px"
                                    fontSize="fs.14"
                                    fontWeight="fw.400"
                                    background="transparent"
                                />
                                <Button
                                    height="45px"
                                    color="rgba(134, 139, 148, 1)"
                                    fontSize="fs.14"
                                    fontWeight="fw.600"
                                    background="transparent"
                                    onClick={() => {
                                        setTitle("");
                                        handleAddLane(title);
                                        setShowInput(false);
                                    }}
                                >
                                    Save
                                </Button>
                            </Flex>
                        )
                            : (
                                <Button
                                    height="45px"
                                    color="rgba(134, 139, 148, 1)"
                                    fontSize="fs.14"
                                    fontWeight="fw.600"
                                    background="transparent"
                                    leftIcon={<FontAwesomeIcon icon={faPlus} color="rgba(134, 139, 148, 1)" />}
                                    onClick={() => setShowInput(true)}
                                >
                                    Add New Board
                                </Button>
                            )}

                    </Flex> */}
                </Flex>
            </Flex>
        </DndContext >
    );
};

export default Board;
