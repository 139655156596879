import React, { useEffect } from 'react';
import { FaPhone, FaPhoneFlip } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import ringtone from "../sounds/video_call.mp3";
import { useSocket } from '../../Socket';

const InitialsAvatar = ({ name }) => {
  const getInitials = (name) => {
    const initials = name.split(' ').map(word => word[0]).join('');
    return initials.toUpperCase();
  };

  return (
    <div className="w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold border-white border-[2px]">
      {getInitials(name)}
    </div>
  );
};

const iconVariants = {
  initial: { scale: 1 },
  animate: {
    scale: 1.1,
    transition: {
      yoyo: Infinity,
      duration: 0.5
    }
  }
};

const notificationVariants = {
  hidden: { y: '-100%', opacity: 0 },
  visible: { y: 0, opacity: 1 },
  exit: { y: '-100%', opacity: 0 }
};

function CallNotification({ isIncoming, onReceive, onReject }) {
  const { socket, callCreatedData, clearCallCreatedData } = useSocket();
  useEffect(() => {
    let audio;
    let timeoutId;

    const playRingtone = async () => {
      if (isIncoming) {
        audio = new Audio(ringtone);
        audio.loop = true;
        try {
          await audio.play();
          timeoutId = setTimeout(() => {
            audio.pause();
            audio.currentTime = 0;
            { callCreatedData?.type === "Directs" && socket.emit('CALL_REJECT', { type: callCreatedData.type, roomId: callCreatedData.roomId }); }
          }, 30000);
        } catch (error) {
          console.error("Error playing the audio file:", error);
        }
      }
    };

    playRingtone();

    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isIncoming]);


  return (
    <AnimatePresence>
      <motion.div
        className="fixed top-10 left-0 right-0 max-w-md mx-auto p-4 bg-black/90 shadow-lg rounded-lg flex items-center justify-between space-x-4"
        variants={notificationVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-center space-x-4">
          {((callCreatedData?.type === "Directs") ? ((callCreatedData?.callOwnerDetail.profilePicture) ?
            <div className='w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center border-white border-[2px]' >
              <img src={callCreatedData?.callOwnerDetail?.profilePicture} alt="Profile" className=' w-full h-full rounded-full flex items-center justify-center ' /></div> :
            <InitialsAvatar name={callCreatedData?.callOwnerDetail?.name || "Test User"} />) :

            (callCreatedData?.channelName.profilePicture ?
              <div className='w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center border-white border-[2px]' >
                <img src={callCreatedData?.channelName?.profilePicture} alt="Profile" className=' w-full h-full rounded-full flex items-center justify-center ' /></div> :
              <InitialsAvatar name={callCreatedData?.channelName || "Test User"} />)
          )}

          <div>
            <p className='text-[10px] text-gray-300'>Incoming Call...</p>
            {callCreatedData?.channelName && <p className="text-lg font-medium text-white capitalize">{callCreatedData?.channelName}</p>}
            <p className={`${callCreatedData?.channelName ? "text-sm" : "text-lg"} font-medium text-white capitalize`}>{callCreatedData?.callOwnerDetail?.name}</p>
          </div>
        </div>
        <div className="flex space-x-4">
          <motion.button
            onClick={onReject}
            className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
            variants={iconVariants}
            initial="initial"
            animate="animate"
          >
            <FaPhoneFlip className="w-5 h-5" style={{ transform: 'rotate(-134deg)' }} />
          </motion.button>
          <motion.button
            onClick={onReceive}
            className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
            variants={iconVariants}
            initial="initial"
            animate="animate"
          >
            <FaPhone className="w-5 h-5" />
          </motion.button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default CallNotification;
