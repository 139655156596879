import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const publicRoute = axios.create({
  baseURL: baseURL,
  timeout: 10 * 1000,
});

const protectedRoute = axios.create({
  baseURL: baseURL,
  timeout: 10 * 1000,
});


protectedRoute.interceptors.request.use(function (config) {
  const token = localStorage.getItem('@apiToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, function (error) {
  return Promise.reject(error);
});

protectedRoute.interceptors.response.use(function (response) {
  if (response.statusCode === 401) {
    return localStorage.clear();
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

export { protectedRoute, publicRoute };
