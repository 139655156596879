import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, AvatarGroup, Box, Flex, Link, ListItem, OrderedList, Text, Tooltip } from "@chakra-ui/react";
import { faHashtag, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconPlus } from "@tabler/icons-react";
import React, { useCallback, useContext } from "react";
import {
	NavLink as RouterLink
} from "react-router-dom";
import "../../../component/style.css";
import { AppContext } from "../../../contexts/AppContext";
import { ChatContext } from "../../../contexts/ChatContexts";
import SkeletonChatDirectItem from "./Skeleton";
import { useSocket } from "../../Socket";
import UserStatus from "./UserStatus";

export default function MessageSideBar() {
	const [appState] = useContext(AppContext);
	const [chatState] = useContext(ChatContext);
	const { onlineUsers } = useSocket();
	const { channels, directs, loaders, unreadCounts } = chatState || {};
	const { isChannelLoading,
		isChannelFetching,
		isDirectsLoading,
		isDirectingFetching } = loaders || {};

	const getDirectName = useCallback((direct) => {
		const withoutMe = direct?.members?.filter(member => member.user?._id !== appState?.user?._id);
		return withoutMe?.map(member => member?.user?.name).join(", ");
	}, [appState]);

	const getDirectId = useCallback((direct) => {
		const withoutMe = direct?.members?.filter(member => member.user?._id !== appState?.user?._id);
		return withoutMe?.map(member => member?.user?._id);
	}, [appState]);

	const getChannelId = useCallback((channel) => {
		const withoutMe = channel?.members?.filter(member => member.user?._id !== appState?.user?._id);
		return withoutMe?.map(member => member?.user?._id);
	}, [appState]);


	const getDirectAvatar = useCallback((direct) => {
		const withoutMe = direct?.members?.filter(member => member.user?._id !== appState?.user?._id);
		return withoutMe?.map(member => <Avatar key={member?.user?._id} sx={{ img: { objectFit: 'contain' } }} name={member?.user?.name} src={member?.user?.profilePicture} border={'1px solid gray.300'} />);
	}, [appState]);

	return (
		<Box background="white" py="25px" px="15px" top="1px" h="100%">
			<Flex justifyContent="space-between" alignItems="center">
				<Text fontSize="fs.24" fontWeight="fw.600" color="#202B3C">Messages</Text>
			</Flex>
			<Box className="scrollBar" h="100%" overflowY="scroll">
				<Accordion defaultIndex={[0, 1]} allowMultiple>
					<AccordionItem borderColor={'transparent'}>
						<Box display={'flex'} alignItems={'center'} className="accordion-item" my={2}>
							<AccordionButton display={'flex'} mr={2} p={2} flex={'0'} h={8} w={10} justifyContent={'center'} alignItems={'center'} borderRadius={'5px'}>
								<AccordionIcon fontSize={'18'} />
							</AccordionButton>
							<Box flex='1' textAlign='left' fontWeight={'600'}>
								Channels
							</Box>
						</Box>
						<AccordionPanel px={0} py={0} pb={4}>
							<OrderedList styleType="none">
								{
									channels?.map((channel) =>
										<div className="flex flex-row justify-between items-center " >
											<Link
												key={channel?._id}
												as={RouterLink}
												to={`/message/channel/${channel?._id}`}
												_activeLink={{ fontWeight: "bold" }}
												className="w-[60%]"
											>
												<ListItem isTruncated fontSize={14} my={2} color={'gray.600'} fontWeight={'400'} display={'flex'} alignItems={'center'} gap={'10px'} ml={-4} px={3} py={1} borderRadius={'6px'} _hover={{ bg: 'gray.100', cursor: 'pointer' }}>
													<Avatar size={'xs'} sx={{ img: { objectFit: 'contain' } }} name={channel?.name} src={channel?.iconURL} border={'1px solid gray.300'} style={{ objectFit: 'contain' }} />
													<FontAwesomeIcon color="gray" icon={channel?.isOpen ? faHashtag : faLock} />
													<div className="flex flex-col ">
														<Text className="font-medium text-base text-black">
															{channel?.name}
														</Text>
														{chatState?.lastMessages?.map((message) => {
															if (message.channelId === channel._id)
																return (
																	<Text isTruncated>
																		{message.text ? (
																			<span className="capitalize" dangerouslySetInnerHTML={{ __html: message.text }} />
																		) : (
																			message.callStatus
																		)}
																	</Text>
																);
														}
														)}
													</div>
												</ListItem>

											</Link>
											<div className="flex flex-row gap-2 items-center mr-4">
												{(() => {
													const onlineCount = getChannelId(channel).reduce((count, value) => {
														return count + (String(onlineUsers[String(value)]) === "true" ? 1 : 0);
													}, 0);
													return (
														<UserStatus count={onlineCount} length={getChannelId(channel).length} />
													);
												})()}
											</div>
											{unreadCounts?.channels?.find(channelCount => channelCount?.channelId === channel?._id)?.unreadCount > 0 && (<Box
												width="16px"
												textAlign="center"
												height="16px"
												fontSize="fs.10"
												fontWeight="fw.600"
												background="#713EDD"
												color="white"
												borderRadius="br.10"
											>
												{
													unreadCounts?.channels?.find(channelCount => channelCount?.channelId === channel?._id)?.unreadCount || 0
												}
											</Box>)}
										</div>

									)
								}
								{
									(isChannelFetching || isChannelLoading) &&
									<SkeletonChatDirectItem noOfItems={isChannelLoading ? 3 : 1} />
								}
							</OrderedList>
						</AccordionPanel>
					</AccordionItem>

					<AccordionItem borderColor={'transparent'}>
						<Box display={'flex'} alignItems={'center'} className="accordion-item" my={2}>
							<AccordionButton display={'flex'} mr={2} p={2} flex={'0'} h={8} w={10} justifyContent={'center'} alignItems={'center'} borderRadius={'5px'}>
								<AccordionIcon fontSize={'18'} />
							</AccordionButton>
							<Box flex='1' textAlign='left' fontWeight={'600'}>
								Directs
							</Box>
							<Link as={RouterLink} to="/message/direct/new" h={'25px'} w={'25px'} p={1} display={'flex'} justifyContent={'center'} alignItems={'center'} bgColor={'transparent'} borderRadius={'100%'} _hover={{ bgColor: 'primary', color: 'white' }}>
								<IconPlus stroke={2} size={18} />
							</Link>
						</Box>
						<AccordionPanel pb={4}>
							<OrderedList styleType="none" display={'flex'} flexDir={'column'} ml={0} rowGap={'10px'}>
								{
									directs?.map((direct) => {
										const onlineCount = getDirectId(direct).reduce((count, value) => {
											return count + (String(onlineUsers[String(value)]) === "true" ? 1 : 0);
										}, 0);

										return (
											<div key={direct?._id} className="flex flex-row justify-between items-center">
												<Link
													as={RouterLink}
													to={`/message/direct/${direct?._id}`}
													_activeLink={{ fontWeight: "bold" }}
													display="flex"
													alignItems="center"
													columnGap="5px"
													className="w-[60%]"
												>
													<AvatarGroup max={1} size="sm" w={39} className="-ml-2">
														{getDirectAvatar(direct)}
													</AvatarGroup>
													<div className="flex flex-col w-[90%] ml-3">
														<Tooltip label={getDirectName(direct)} aria-label="Direct name tooltip">
															<ListItem isTruncated fontSize={14} fontWeight="400">
																<Text className="font-medium text-base text-black">
																	{getDirectName(direct)}
																</Text>
															</ListItem>
														</Tooltip>
														<ListItem isTruncated fontSize={14} fontWeight="400">
															{chatState?.lastMessages?.map((message) => {
																if (message.channelId === direct._id)
																	return (
																		<Text isTruncated whiteSpace={"nowrap"} height={"20px"}>
																			{message.text ? (
																				<span className="capitalize" dangerouslySetInnerHTML={{ __html: message.text }} />
																			) : (
																				message.callStatus
																			)}
																		</Text>
																	);
															}
															)}
														</ListItem>
													</div>
												</Link>
												<div className="flex flex-row gap-2 items-center">
													<UserStatus count={onlineCount} length={getDirectId(direct).length} />
												</div>
												{unreadCounts?.directs?.find(directCount => directCount.channelId === direct?._id)?.unreadCount > 0 && (<Box
													width="16px"
													textAlign="center"
													height="16px"
													fontSize="fs.10"
													fontWeight="fw.600"
													background="#713EDD"
													color="white"
													borderRadius="br.10"
												>
													{
														unreadCounts?.directs?.find(directCount => directCount.channelId === direct?._id)?.unreadCount || 0
													}
												</Box>)}
											</div>
										);
									})
								}
							</OrderedList>
							{
								(isDirectingFetching || isDirectsLoading) &&
								<SkeletonChatDirectItem noOfItems={isDirectsLoading ? 3 : 1} />
							}
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Box>
		</Box>
	);
}