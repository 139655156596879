// user
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_MANAGERS = 'GET_ALL_MANAGERS';
export const GET_ALL_MEMBERS = 'GET_ALL_MEMBERS';
export const GET_SEARCH_USERS = 'GET_SEARCH_USERS';
export const GET_MY_QUOTES = "GET_MY_QUOTES";

// static
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';

// products
export const GET_PRODUCTS = `GET_PRODUCTS`;

// ebooks
export const GET_EBOOK = `GET_EBOOK`;

// Task
export const GET_TASKS = `GET_TASKS`;
export const GET_MY_TASKS = `GET_MY_TASKS`;

// Comments
export const GET_COMMENTS = `GET_COMMENTS`;
//Channel
export const GET_CHANNEL = `GET_CHANNEL`;
export const GET_MY_CHANNELS = 'GET_MY_CHANNELS';

// Directs
export const GET_MY_DIRECTS = 'GET_MY_DIRECTS';

//projects
export const GET_PROJECTS = `GET_PROJECTS`;

//manager
export const GET_ADD_MANAGER = 'GET_ADD_MANAGER';
export const GET_REMOVE_MANAGER = 'GET_REMOVE_MANAGER';

//skills 
export const GET_SKILLS = "GET_SKILLS";


//messages
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_UNREAD_MESSAGE_COUNT = 'GET_UNREAD_MESSAGE_COUNT';
export const GET_LAST_MESSAGES = 'GET_LAST_MESSAGES';

//notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_UNREAD_NOTIFICATION_COUNT = 'GET_UNREAD_NOTIFICATION_COUNT';
export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ';