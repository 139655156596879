import isHotkey from "is-hotkey";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";

import { Box, FormControl } from "@chakra-ui/react";
import {
  HOTKEYS,
  SlateElement,
  SlateLeaf,
  toggleMark,
} from "./SlateEditorUtil";
import { SlateToolbar } from "./SlateToolbar";

export const SlateInputField = ({
  initValue,
  required,
  fullWidth,
  placeholder,
  onChange,
  textContainerStyle,
  setValue, name
}) => {
  const [slateValue, setSlateValue] = useState(initValue);
  const [plainText, setPlainText] = useState("");
  console.log(slateValue, plainText, "plainText");
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const renderElement = useCallback((props) => <SlateElement {...props} />, []);
  const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);


  const extractTextFromSlateValue = (nodes) => {
    return nodes
      .map((node) => {
        if (node.type === "image") {
          return `<url>${node.url}<url>`;
        } else if (node.children) {
          return extractTextFromSlateValue(node.children);
        } else {
          return node.text;
        }
      })
      .join("");
  };

  useEffect(() => {
    setValue(name, plainText);
  }, [plainText]);



  return (
    <FormControl isRequired={required} width={fullWidth ? "100%" : "auto"}>
      <Box boxShadow="md" borderWidth="0px" borderRadius="md" className="slate-editor slate-editor-wrapper">
        <Slate
          editor={editor}
          initialValue={slateValue}
          onChange={(value) => {
            if (value.length === 0) {
              value = initValue;
            } else {
              console.log("in else block ");
            }
            setSlateValue(value);
            onChange(JSON.stringify(value));
            setPlainText(extractTextFromSlateValue(value));
          }}
        >
          <SlateToolbar />
          <Editable
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            renderElement={renderElement}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
            style={{ ...textContainerStyle }}
          />
        </Slate>
      </Box>
    </FormControl>
  );
};
