import { Box, Button, HStack, Spinner } from '@chakra-ui/react';
import { IconBold, IconCode, IconFilePlus, IconItalic, IconList, IconListNumbers, IconPlayerPlayFilled, IconStrikethrough, IconX } from '@tabler/icons-react';
import isHotkey from 'is-hotkey';
import { capitalize } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Editor, Element as SlateElement, Node as SlateNode, Transforms, createEditor, Path, Range } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import uppyUtility from '../../../config/uppi';
import { AddLinkButton, BlockButton, CodeBlockButton, MarkButton, RemoveLinkButton } from './Buttons';
import Element from './Element';
import Leaf from './Leaf';
import Toolbar from './Toolbar';
import style from './style.module.css';
import { HOTKEYS, SHORTCUTS, prismThemeCss, serialize, toggleMark, withInlines, withInlineCode, handleShiftEnter, LIST_TYPES, useDecorate, SetNodeToDecorations, handleBacksapace, handleBackspace } from './ulility';
import { useSocket } from '../../Socket';

// TODO:
// [UX]: When users hits Shift + Enter 2 times, end current formatting block and move cursor to out of the block
// [UI] Prism css is not applying on message input and on message history view item

const initialValue = [
  {
    type: 'paragraph',
    children: [{
      text: '',
    }]
  }
];

// const emptyValueRegEx = /<p\s+style="text-align:\s*left"\s*>\s*<\/p>/gi;

function MessageInput({ files, openFileDialog }) {
  const { channelId, channelType } = useParams();
  const [editor] = useState(() => withInlines(withHistory(withReact(createEditor()))));
  const [shiftEnterCount, setShiftEnterCount] = useState(0);
  const [value, setValue] = useState(initialValue);
  const { socket } = useSocket();

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  const resetEditor = useCallback(() => {
    editor.children = initialValue;
    editor.normalize({ force: true });
    Transforms.select(editor, Editor.start(editor, []));

    setValue([...initialValue]);

  }, [editor]);

  const onChange = useCallback(
    (newValue) => {
      setValue(newValue);
    },
    [editor]
  );

  const handleDOMBeforeInput = useCallback(
    (e) => {
      queueMicrotask(() => {
        const pendingDiffs = ReactEditor.androidPendingDiffs(editor);

        const scheduleFlush = pendingDiffs?.some(({ diff, path }) => {
          if (!diff.text.endsWith(' ')) {
            return false;
          }

          const { text } = SlateNode.leaf(editor, path);
          const beforeText = text.slice(0, diff.start) + diff.text.slice(0, -1);
          if (!(beforeText in SHORTCUTS)) {
            return;
          }

          const blockEntry = Editor.above(editor, {
            at: path,
            match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n),
          });
          if (!blockEntry) {
            return false;
          }

          const [, blockPath] = blockEntry;
          return Editor.isStart(editor, Editor.start(editor, path), blockPath);
        });

        if (scheduleFlush) {
          ReactEditor.androidScheduleFlush(editor);
        }
      });
    },
    [editor]
  );

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const { nativeEvent } = event;
      if (event.shiftKey) {
        handleShiftEnter(editor, nativeEvent);
      } else {
        handleSubmit(event);
      }
    } else if (event.key === "ArrowDown") {
      // deactivateCodeBlock(editor);
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const [node, path] = Editor.parent(editor, selection);
        if (
          SlateElement.isElement(node) &&
          (node.type === "code-line" || LIST_TYPES.includes(node.type))
        ) {
          const end = Editor.end(editor, path);
          // Check if we're at the end of the current block
          if (Editor.isEnd(editor, selection.anchor, end)) {
            event.preventDefault();
            const nextPath = Path.next(path);

            if (Editor.hasPath(editor, nextPath)) {
              // If there's a next block, move to its start
              Transforms.select(editor, Editor.start(editor, nextPath));
            } else {
              // If there's no next block, insert a new paragraph
              insertNewParagraph(path);
            }
          }
        }
      }
    }
    else if (event.key === "Backspace") {
      handleBackspace(editor);
    }

    function insertNewParagraph(path) {
      const parentPath = Path.parent(path);
      const nextPath = Path.next(parentPath);

      Transforms.insertNodes(
        editor,
        { type: "paragraph", children: [{ text: "" }] },
        { at: nextPath }
      );
      Transforms.select(editor, Editor.start(editor, nextPath));
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
  };

  const renderFilePreview = () => {
    return (files.length > 0 &&
      <HStack m={2}>
        {
          files.map(fileObj =>
            <FilePreviewItem file={fileObj} key={fileObj.id} />
          )
        }
      </HStack>);
  };

  const containsOnlyBlankPTags = (html) => {
    const cleanHtml = html.replace(/\s+/g, '');

    const nonBlankPRegex = /<p[^>]*>(.*?)<\/p>/i;

    const nonBlankLiRegex = /<li[^>]*>(.*?)<\/li>/ig;

    const hasNonBlankP = nonBlankPRegex.test(cleanHtml) && cleanHtml.match(nonBlankPRegex)[1].trim() !== '';
    const hasNonBlankLi = nonBlankLiRegex.test(cleanHtml) && cleanHtml.match(nonBlankLiRegex).some(item => item.replace(/<\/?li[^>]*>/g, '').trim() !== '');

    return !(hasNonBlankP || hasNonBlankLi);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const html = serialize(editor.children);
    let messageType = "";

    const emptyValueRegEx = containsOnlyBlankPTags(html);

    if (emptyValueRegEx && !files.length)
      return;

    if (files.length && !emptyValueRegEx) {
      messageType = "TEXT_MEDIA";
    } else if (files.length) {
      messageType = "MEDIA";
    } else {
      messageType = "TEXT";
    }

    const payload = {
      channelId,
      chatType: capitalize(channelType) + 's',
      text: emptyValueRegEx ? "" : html,
      // text: html,
      type: messageType,
      fileURL: files?.map((file) => file.uploadURL),
    };

    socket.emit('MESSAGE_SEND', payload);
    resetEditor();
    uppyUtility.cancelAll();
  };

  const decorate = useDecorate(editor);

  const resetFormatting = useCallback(() => {
    Transforms.setNodes(
      editor,
      { type: 'paragraph' },
      { match: (n) => Editor.isBlock(editor, n) }
    );
    Transforms.unsetNodes(editor, Object.keys(Text.decorations), { match: Text.isText });
  }, [editor]);

  return (
    <form onSubmit={handleSubmit}>
      <Box className='slate-editor slate-editor-wrapper' border={'1px solid'} borderRadius={'10px'} borderColor={'gray.300'} boxShadow={' 0 -2px 10px 4px white'} pos={'relative'} zIndex={1} maxH='calc(100vh-80%)' >
        <Slate editor={editor} initialValue={initialValue} value={value} onChange={onChange}>
          <Toolbar>
            <MarkButton type={value?.[value?.length - 1]?.type} format="bold" icon={IconBold} />
            <MarkButton type={value?.[value?.length - 1]?.type} format="italic" icon={IconItalic} />
            <MarkButton type={value?.[value?.length - 1]?.type} format="strike" icon={IconStrikethrough} />
            <MarkButton type={value?.[value?.length - 1]?.type} format="code" icon={IconCode} />
            <CodeBlockButton editors={editor} type={value?.[value?.length - 1]?.type} format="code-block" />
            <AddLinkButton type={value?.[value?.length - 1]?.type} />
            <RemoveLinkButton type={value?.[value?.length - 1]?.type} />
            <BlockButton editors={editor} type={value?.[value?.length - 1]?.type} format="numbered-list" icon={IconListNumbers} />
            <BlockButton editors={editor} type={value?.[value?.length - 1]?.type} format="bulleted-list" icon={IconList} />
            <Button
              onClick={openFileDialog}
              size={'sm'}
              variant={"unstyled"}
              border={'1px solid'}
              borderColor={'gray.200'}
              p={1}>
              <Box
                display={"flex"}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <IconFilePlus stroke={2} size={18} />
              </Box>
            </Button>
            {/* 
              TODO: Enable insert/delete Links
            */}
            {/* 
              TODO: Add File upload capabilities using Uppy
            */}
            {/* 
              TODO:  Add Audio/Video/Screen recording capabilities
             */}
            {/* TODO: Integrate Add link & remove link button */}
            {/* <AddLinkButton />
            <RemoveLinkButton /> */}
          </Toolbar>
          <SetNodeToDecorations />
          <Editable
            decorate={decorate}
            className={style.inputText}
            placeholder='Type something or Drop something here to send'
            onDOMBeforeInput={handleDOMBeforeInput}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            renderPlaceholder={({ children, attributes }) => (
              <Box {...attributes} mt={'10px'}>
                <p>{children}</p>
              </Box>
            )
            }
            onKeyDown={onKeyDown}
          />
          <style>{prismThemeCss}</style>
        </Slate>
        {renderFilePreview()}
      </Box>
    </form>
  );
}

export default memo(MessageInput);

const FilePreviewItem = ({ file }) => {
  const removeFile = () => {
    uppyUtility.removeFile(file.id);
  };
  return (
    <Box
      key={file.id}
      height={'4rem'}
      width={'4rem'}
      bg={`url(${file.preview || file?.uploadURL})`}
      bgSize={'cover'}
      position={'relative'}
      borderRadius={'10px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      border={'1px solid'}
      borderColor={'gray.200'}
      cursor={'pointer'}
    >
      {
        !file?.progress?.uploadComplete &&
        <Spinner />
      }
      {
        file?.progress?.uploadComplete && file?.meta?.type.startsWith('video/') &&
        <IconPlayerPlayFilled color="white" />
      }
      <Button
        variant={'unstyled'}
        color={'white'}
        bg="blackAlpha.600"
        p={1}
        minW="20px"
        w="20px"
        minH="20px"
        h="20px"
        borderRadius={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        pos={'absolute'}
        right={'0'}
        top={'0'}
        transform={'translate(50%, -50%)'}
        onClick={removeFile}
        _hover={{ bg: 'blackAlpha.700' }}
      >
        <IconX stroke={4} size={18} />
      </Button>
    </Box>
  );
};