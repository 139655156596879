import { useEffect } from "react";
import { useSocket } from "../../../Socket";

export function LeaveScreen({ setIsMeetingLeft }) {
  const { clearCallCreatedData } = useSocket();

  useEffect(() => {
    clearCallCreatedData();
  }, [clearCallCreatedData]);
  return (
    <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center">
      <h1 className="text-white text-4xl">Call End!</h1>
    </div>
  );
}
