
import {
  Box, Button, Flex, Text, useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../contexts/AppContext";
import CreateDialog from "./CreateDialog";
import ProjectTable from "./ProjectTable";
import { schema } from "./form";

export default function Project() {

  const [stateApp] = useContext(AppContext);
  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      key: "",
      icon: "",
      team: []
    },
    resolver: yupResolver(schema)
  });
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      reset();
    }
  });

  return (
    <>
      <Box
        px={{ base: '0', md: '15px', lg: '35px', xl: '35px', }}
        py={{ base: '0', md: '15px', lg: '35px', xl: '27px', }}
        bg='rgba(247, 247, 247, 1)' >
        <Box background="white" borderRadius="br.10" padding="25px" height="calc(100dvh - 150px)" overflowY="scroll" className="scrollBar">
          <Flex justifyContent="space-between" alignItems="center" pb="20px">

            <Text fontSize="fs.22" fontWeight="fw.700" >Project</Text>
            {['Mentor'].includes(stateApp?.user?.role) &&
              <Box >
                <Button onClick={onOpen} px={{ md: "15px", lg: "30px" }} py="8px" background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)" _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">+ Add project</Button>
              </Box>
            }
          </Flex>

          <CreateDialog isOpen={isOpen} onClose={onClose} control={control} handleSubmit={handleSubmit} errors={errors} />
          <ProjectTable />
        </Box>
      </Box>

    </>
  );
}