import React, { useState } from 'react';
import { Avatar, Box, Flex, Text, Wrap, WrapItem, } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { SlateInputField } from "../../RichTextBox/SlateInputField";
import { GET_COMMENTS } from '../../../apis/constants.api';
import { useQuery } from '@tanstack/react-query';
import { getCommentsById } from '../../../apis/comments.api';
import UpdateCommentActions from './UpdateCommentActions';

const CommentList = ({ id, stateApp, controlComment, initialValue, setValueComment }) => {

  const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
  const [page, setPage] = useState(1);
  const [isEditId, setIsEditId] = useState("");

  const { data: comments } = useQuery({
    queryKey: [GET_COMMENTS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE,
      taskId: id
    }],
    queryFn: getCommentsById,
  });

  const commentsList = comments?.data?.data || [];

  const extractData = (item) => {
    const urlRegex = /<url>(.*?)<url>/;
    const urlMatch = item?.plainText?.match(urlRegex);
    const url = urlMatch ? urlMatch[1] : '';
    const text = item?.plainText?.replace(urlRegex, '').trim();
    return { text, url };
  };


  return (
    <>
      {commentsList?.length > 0 && commentsList?.map((e) => {
        return (<> <Box mt="15px" width={"100%"}>
          <Flex alignItems="center" gap="10px" mt="10px">
            <Wrap>
              <WrapItem>
                <Avatar src={stateApp.user?.profilePicture} name={stateApp.user?.name} size={'sm'} />
              </WrapItem>
            </Wrap>

            {(isEditId === e._id) ? <Controller
              name="text"
              control={controlComment}
              render={({ field }) =>
                <SlateInputField
                  placeholder="Add a comment..."
                  initValue={e.text ? JSON.parse(e.text) : initialValue}
                  textContainerStyle={{ minHeight: 35, margin: 10, padding: "0px 13px", width: "535px" }}
                  onChange={field.onChange}
                  setValue={setValueComment}
                  name="plainText"
                />
              }
            /> : <Box>
              <Text fontSize="fs.16" fontWeight="fw.500" >{stateApp?.user?.name}</Text>
              <Text fontSize="fs.14" fontWeight="fw.400" >{extractData(e).text}</Text>
              {extractData(e).url ? <img src={extractData(e).url} alt="image" /> : <></>}
            </Box>}

          </Flex>
          {(e.author === stateApp.user?._id) && <UpdateCommentActions e={e} isEditId={isEditId} setIsEditId={setIsEditId} />}
        </Box >
        </>);
      })}
    </>

  );
};

export default CommentList;