import {
  Button, ButtonGroup, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import reject from "../../../../src/assets/icon/reject.png";
import { GET_COMMENTS } from '../../../apis/constants.api';
import { deleteComments } from '../../../apis/comments.api';

export default function DeleteCommentDialog(props) {
  const { isOpen, onClose, setIsfocusComment, comment } = props;
  const queryClient = useQueryClient();
  const { reset: resetComment, handleSubmit: handleSubmitComment, } = useForm();


  const { mutate: deleteComment, isLoading } = useMutation(deleteComments, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_COMMENTS] });
      resetComment();
      setIsfocusComment(false);
      onClose();
    },

  });

  useEffect(() => {
    return () => {
      resetComment();
    };
  }, []);

  const onDeleteComments = async () => {
    await deleteComment(comment._id);
  };

  return (
    <Modal closeOnOverlayClick={false} onClose={onClose} size="lg" isOpen={isOpen} isCentered >
      <form onSubmit={handleSubmitComment(onDeleteComments)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Image alt='reject' src={reject} />
          </ModalHeader>
          <ModalCloseButton border="1px solid #868B944D" />
          <ModalBody>
            <Text fontSize="fs.18" fontWeight="fw.600">{`Archive ${comment?.plainText} Request`}</Text>
            <Text fontSize="fs.14" fontWeight="fw.400" color="#475467" mt="5px" >Are you sure you want to delete this comment?</Text>

            <ButtonGroup variant='outline' spacing='5' width="100%" my="18px">
              <Button {...actionButtonChakraProps} onClick={onClose}>Cancel</Button>
              <Button  {...actionButtonChakraProps} type='submit' background={'red.500'} color="white" _hover={{ background: "red.600" }}>
                Delete
                {isLoading && <Spinner size="sm" ml={2} />}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}

const actionButtonChakraProps = {
  fontWeight: "fw.600",
  width: "100%",
  borderRadius: "8px",
  height: "44px",
  fontSize: "fs.16",
};