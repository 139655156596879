import { protectedRoute } from '../config/api.config';

export async function getUserDataAPI(payload) {
  return protectedRoute.get('/user', payload);
}

export async function updateCareerDetailsAPI(payload) {
  return protectedRoute.post('/user/update-career-details', payload);
}

export async function updateSchoolInfoAPI(payload) {
  return protectedRoute.post('/user/update-school-info', payload);
}

export async function updateProfessionAPI(payload) {
  return protectedRoute.post('/user/update-profession', payload);
}

export async function updateInterestedCareersAPI(payload) {
  return protectedRoute.post('/user/update-interested-careers', payload);
}

export async function updateUserIdDocAPI(payload) {
  return protectedRoute.post('/user/update-user-id-doc', payload);
}

export async function updateUserDocAPI(payload) {
  return protectedRoute.post('/user/update-user-docs', payload);
}

export async function getAllUserDataAPI({ queryKey }) {
  const [, queryParameters] = queryKey;

  return protectedRoute.get('/user/allUsers?', { params: queryParameters });
}

export async function getPlatformUserDataAPI({ queryKey }) {
  const { skip, limit, search } = queryKey[1];

  return protectedRoute.get('/user/platformUsers', { params: { skip, limit, search } });
}

export async function updateUserStatusAPI({ userId, status, reason }) {
  return protectedRoute.patch(`/user/${userId}/status`, { status, reason });
}

export async function addUserManagerAPI(payload) {
  return protectedRoute.post('/user/add-manager', payload);
}

export async function removeUserManagerAPI({ id }) {
  return protectedRoute.patch(`/user/${id}/remove-manager`);
}

export async function updateProfileAPI(payload) {
  return protectedRoute.patch('/user/update-profile', payload);
}

export async function searchUsersAPI({ queryKey }) {
  const { skip = 0, limit = 25, search } = queryKey[1];
  return protectedRoute.get('/user/search', { skip, limit, search });
}

export async function updateUserRoleAPI(payload) {
  return protectedRoute.patch('/user/update-role', payload);
}

export async function getMyQuotes() {
  return protectedRoute.get('/user/my-quote');
}