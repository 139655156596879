import { Avatar, AvatarBadge, AvatarGroup, Box, Button, Flex, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Popover, PopoverContent, PopoverTrigger, Spinner, Text, WrapItem, useToast } from "@chakra-ui/react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisVertical, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconArrowLeft, IconArrowRight, IconX } from "@tabler/icons-react";
import _, { capitalize } from 'lodash';
import moment from "moment";
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { TbLoader3 } from "react-icons/tb";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import uppyUtility from "../../../config/uppi";
import { AppContext } from "../../../contexts/AppContext";
import { ChatContext } from "../../../contexts/ChatContexts";
import useIsMe from "../../../hooks/useIsMe";
import { setChannel } from "../../../store/channelSlice";
import { setToken } from "../../../store/tokenSlice";
import HTMLComponent from '../../HTMLComponent';
import { useSocket } from "../../Socket";
import MessageInput from "../MessageInput";
import MessageFileItemPreview from "./MessageFileItemPreview";
import FileViewer from "./FileViewer";


function MessageHistoryView() {
    const { socket, callCreatedData, onlineUsers } = useSocket();
    const params = useParams();
    const uppy = useRef(uppyUtility).current;
    let renderRef = useRef(0).current;
    const { channelId, channelType } = params;
    const [chatState, __, { fetchNextPage, refetchUnreadCount }] = useContext(ChatContext);
    const { messageHistory, loaders: { areMessagesLoading, areMessagesFetching } } = chatState;
    const [appState] = useContext(AppContext);
    const [files, setFiles] = useState([]);
    const [call, setCall] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (socket && appState?.user?._id) {
            socket.emit('CHAT_NOTIFICATION_READ', {
                chatType: capitalize(channelType) + 's',
                userId: appState?.user?._id,
                chatId: channelId,
            });
            refetchUnreadCount();
        }
    }, [socket, appState?.user?._id, channelId, channelType, messageHistory]);

    useEffect(() => {
        renderRef++;

        if (renderRef > 1) {
            return;
        }

        uppy.on('files-added', (files) => {
            setFiles((prevFiles) => [...prevFiles, ...files]);
        });

        uppy.on('upload-success', (file, response) => {
            setFiles((prevFiles) =>
                prevFiles?.map(fileObj => {
                    if (fileObj.id === file.id) {
                        return file;
                    }

                    return fileObj;
                })
            );
        });

        uppy.on('upload-error', (file, error, response) => {
            setFiles((prevFiles) =>
                prevFiles?.map(fileObj => {
                    if (fileObj.id === file.id) {
                        return file;
                    }

                    return fileObj;
                })
            );
        });

        uppy.on('complete', (result) => {
            const uploadedFiles = result.successful;
            const failedFiles = result.failed;
            setFiles((prevFiles) => {
                return prevFiles?.map(fileObj => {
                    const updatedFile = uploadedFiles.find(uf => uf.id === fileObj.id) || failedFiles.find(ff => ff.id || fileObj.id);
                    if (updatedFile) {
                        return updatedFile;
                    }

                    return fileObj;
                });
            }
            );
        });

        uppy.on('file-removed', (file) => {
            setFiles((prevFiles) =>
                prevFiles.filter(pf => pf.id !== file.id)
            );
        });

        return () => {
            uppy.off();
        };
    }, []);

    dispatch(setChannel({ roomId: callCreatedData?.roomId, channelType: callCreatedData?.type }));

    useEffect(() => {
        if (callCreatedData?.roomId && call) {
            navigate(`/call/${callCreatedData?.roomId}`);
        }
    }, [callCreatedData]);

    useEffect(() => {
        if (callCreatedData?.token) {
            dispatch(setToken(callCreatedData?.token));
        }
        setCall(false);
    }, [callCreatedData]);

    const onDrop = useCallback((acceptedFiles) => {
        uppy.addFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true
    });
    const selectedChannel = useMemo(() => {
        const find = (channelType === 'channel' ? chatState.channels : chatState.directs).find(item => item._id === channelId);

        return find;
    }, [chatState, channelId, channelType]);

    const getDirectName = useCallback((direct) => {
        const withoutMe = direct?.members?.filter(member => member.user?._id !== appState?.user?._id);
        return withoutMe?.map(member => member?.user?.name).join(", ");
    }, []);

    const getDirectAvatar = useCallback((direct) => {
        const withoutMe = direct?.members?.filter(member => member.user?._id !== appState?.user?._id);
        return withoutMe?.map(member => <Avatar sx={{ img: { objectFit: 'contain' } }} name={member?.user?.name} src={member?.user?.profilePicture} border={'1px solid gray.300'} />);
    }, []);

    const renderChannelAvatar = useCallback(() => {
        if (selectedChannel) {
            if (channelType === 'channel') {
                return <Avatar name={selectedChannel.name} height="40px" width="40px" src={selectedChannel.iconURL} />;
            }

            return (
                <AvatarGroup max={1} size="md">
                    {getDirectAvatar(selectedChannel)}
                </AvatarGroup>
            );
        }

        return 'N/A';
    }, [selectedChannel, channelType]);

    const handlePageChange = useCallback(() => {
        fetchNextPage();
    }, [fetchNextPage]);

    const channelName = useMemo(() => {
        if (selectedChannel) {
            if (channelType === 'channel') {
                return selectedChannel.name;
            }

            return getDirectName(selectedChannel);
        }

        return 'N/A';
    }, [selectedChannel, channelType]);

    const channelMembersNames = useMemo(() =>
        selectedChannel?.members?.map(member => _.capitalize(member?.user?.name)).filter(m => m).join(", "),
        [selectedChannel]);

    const channelMessages = useMemo(() => {
        const allMessages = messageHistory?.[channelId]?.messages || [];
        const groupedByDate = _.groupBy(allMessages, (item) => moment(item.createdAt).format('LL'));
        const groupedByDateAndTime = {};

        Object.keys(groupedByDate).map(key => {
            groupedByDateAndTime[key] = {};

            const groupedByTime = _.groupBy(groupedByDate[key], (item) => `${moment(item.createdAt).format('LT')}`);
            groupedByDateAndTime[key] = groupedByTime;
        });

        let sortedData = _(groupedByDateAndTime)
            .toPairs()
            .orderBy([([date]) => new Date(date)], ['asc'])
            .fromPairs()
            .value();

        return sortedData;
    }, [channelId, chatState.messageHistory, areMessagesFetching, areMessagesLoading]);

    if (!channelId || !channelType || !selectedChannel) {
        return (
            <Box bg="transparent" margin="35px" width="100%" flex={'1 1 auto'}>
            </Box>
        );
    }

    if (!socket) {
        console.error('Socket is not defined');
        return;
    }

    const createVideoCall = async () => {
        await socket.emit("CALL_CREATE", { directId: channelId, type: channelType, channelId: channelId });
        setCall(true);
        setIsLoading(true);
    };

    return (
        <Box margin="35px" width="100%" {...getRootProps()}>
            <Flex flexDir={'column'} px="30px" py="25px" background="white" borderRadius="br.10" h={'100%'} boxShadow={'0 0 10px 0px rgba(0, 0, 0, 0.1)'}>
                {/* Header Section */}
                <Flex justifyContent="space-between" alignItems="center" mb="12px">
                    <Flex alignItems="center" gap="15px" flex={1} minWidth={0}>
                        <Box flexShrink={0}>
                            <WrapItem>
                                {renderChannelAvatar()}
                            </WrapItem>
                        </Box>
                        <Box flex={1} minWidth={0}>
                            <Text fontSize="fs.16" fontWeight="fw.500" noOfLines={1}>{channelName}</Text>
                            {
                                channelType === "channel" &&
                                <Text
                                    fontSize="fs.12"
                                    fontWeight="fw.400"
                                    noOfLines={1}
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    width="100%"
                                >
                                    {channelMembersNames}
                                </Text>
                            }
                        </Box>
                    </Flex>
                    <Flex alignItems="center" gap="27px" flexShrink={0}>
                        <Flex width="30px" alignItems="center" justifyContent="center" height="30px" background="#2D8CFF" borderRadius="br.4" cursor="pointer" onClick={async () => {
                            if (!isLoading) {
                                await createVideoCall();
                            }
                        }}
                        >
                            {isLoading ? <TbLoader3 className="animate-spin text-xl text-white" /> : <FontAwesomeIcon icon={faVideo} style={{ color: "white" }} />
                            }
                        </Flex>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Flex>
                </Flex>
                {/* Chat history */}
                <Flex flexDir={'column'} pos={"relative"} flex={'1 1 auto'} overflow={'hidden'}>
                    <Box flex="1 1 auto" id="chat-history-scroller" display={'flex'} flexDirection="column-reverse" borderTop="1px solid #E7E8EA" overflowY="scroll" pb="15px">
                        <InfiniteScroll
                            dataLength={messageHistory?.[channelId]?.messages?.length || 0}
                            next={handlePageChange}
                            hasMore={messageHistory?.[channelId]?.messages?.length < messageHistory?.[channelId]?.total}
                            pullDownToRefresh={false}
                            inverse
                            style={{ display: 'flex', flexDirection: 'column-reverse', position: 'relative', overflowY: 'auto', flex: '1 1 auto' }}
                            scrollableTarget="chat-history-scroller"
                            loader={<Text textAlign={'center'}><Spinner my="2.5" size={'lg'} color="purple.500" /></Text>}
                            scrollThreshold={0.25}
                        >
                            {
                                (areMessagesLoading || areMessagesFetching) && <Flex py={3} justify={'center'}><Spinner size="md" color="purple.500" /></Flex>
                            }
                            <MessageGroupByDate channelMessages={channelMessages} />
                            <Box id="anchor" style={{ overflowAnchor: 'auto' }} />
                        </InfiniteScroll>
                    </Box>
                    <Box>
                        <input {...getInputProps()} />
                        <MessageInput files={files} openFileDialog={open} />
                    </Box>
                    {
                        isDragActive &&
                        <Box pos={'absolute'} top="0" left="0" w="100%" h="100%" borderRadius={10} bg="white" zIndex={5} border={'1px dashed gray'}>
                            <Flex h="100%" flexDir={'column'} justify={'center'} align={'center'} rowGap={2}>
                                <HStack>
                                    <Image src="/assets/icons/docx.png" w={50} h={50} />
                                    <Image src="/assets/icons/pdf.png" w={50} h={50} />
                                    <Image src="/assets/icons/ppt.png" w={50} h={50} />
                                    <Image src="/assets/icons/xls.png" w={50} h={50} />
                                </HStack>
                                <Text fontSize={'2xl'}>Drop files here</Text>
                            </Flex>
                        </Box>
                    }
                </Flex>
            </Flex>
            <Modal size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton border="1px solid #868B944D" />
                    <ModalBody pos={'relative'}>
                        {/* <DocViewer documents={imgs} pluginRenderers={DocViewerRenderers} />; */}
                        <Button
                            variant={'unstyled'} p={2} bg={'white'} borderRadius={'100%'}
                            pos={'absolute'} left={0} top={'50%'}
                        >
                            <IconArrowLeft stroke={2} size={24} />
                        </Button>
                        <Button
                            variant={'unstyled'} p={2} bg={'white'} borderRadius={'100%'}
                            pos={'absolute'} right={0} top={'50%'}
                        >
                            <IconArrowRight stroke={2} size={24} />
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default memo(MessageHistoryView);

const MessageGroupByDate = memo((props) => {
    const { channelMessages } = props || {};

    return Object.keys(channelMessages)?.reverse()?.map?.((dateKey) =>
        <Box key={'message_group_date_' + dateKey}>
            <Flex pos={"relative"} justify={'center'} p={2} bottom={0} >
                <Text pos={"relative"} zIndex={1} bg="gray.100" fontSize={'12'} fontWeight={'500'} py={2} px={4} borderRadius={'20px'}>{dateKey}</Text>
                <Box pos={'absolute'} w={"100%"} left="0" top="50%" borderTop="2px solid" borderColor={'gray.200'} zIndex={0} />
            </Flex>
            {
                Object.keys(channelMessages?.[dateKey])?.map((timeAndUserKey) =>
                    <Box key={'message_time_key_' + timeAndUserKey}>
                        {
                            channelMessages[dateKey][timeAndUserKey]?.map((message, index) =>
                                <MessageItem
                                    key={'message_item_' + message?._id}
                                    message={message}
                                    isPreviousMessageIsMine={message?.senderId?._id === channelMessages[dateKey][timeAndUserKey]?.[index - 1]?.senderId?._id}
                                />
                            )
                        }
                    </Box>
                )
            }
        </Box>
    );
}, (pp, np) => _.isEqual(pp, np));

const CallStatusMessage = ({ _id, messageTime, callStatus, roomId, duration }) => {
    const { callEndStatus, clearCallEndStatus } = useSocket();
    const navigate = useNavigate();

    useEffect(() => {
        if (callEndStatus?.callStatus === "Call ended") {
            clearCallEndStatus();
        }
    }, [callEndStatus]);

    const JoinCallHandler = () => {
        navigate(`/call/${roomId}`);
    };

    return (
        <div
            className="max-w-xs mx-auto p-4 bg-[#edf2f7] shadow-lg rounded-xl flex items-center justify-between space-x-4"
        >
            <div className="flex items-center space-x-4">
                <div>
                    {_id !== callEndStatus?._id && (
                        <>
                            <p className='text-sm text-black'>{callStatus}</p>
                            <p className="text-base font-medium text-black capitalize">{messageTime} {duration && `| ${duration}`}</p>
                        </>
                    )}
                    {_id === callEndStatus?._id && (
                        <>
                            <p className='text-sm text-black'>{callEndStatus?.callStatus}</p>
                            <p className="text-base font-medium text-black capitalize">{messageTime} {callEndStatus?.duration && `| ${callEndStatus?.duration}`}</p>
                        </>
                    )}

                </div>
            </div>
            {(callStatus === "In Progress") &&
                <div className={`flex space-x- ${_id === callEndStatus?._id && "hidden"}`}>
                    <button
                        onClick={JoinCallHandler}
                        className="p-2 focus:outline-none text-sm font-semibold focus:ring-2 focus:ring-green-400 bg-green-500 text-white rounded-full hover:bg-green-600"
                    >
                        Join Call
                    </button>
                </div>}
        </div>
    );
};

const MessageItem = memo((props) => {
    const toast = useToast();
    const [activeDoc, setActiveDoc] = useState(0);
    const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
    const [appState] = useContext(AppContext);
    const [chatState] = useContext(ChatContext);
    const { message, isPreviousMessageIsMine } = props || {};
    const { fileURL, senderId, text, createdAt, type, duration, callStatus, _id, roomId } = message;
    const isMe = useIsMe(senderId._id);
    const navigate = useNavigate();
    const { onlineUsers, socket } = useSocket();

    const messageTime = useMemo(() => {
        return moment(createdAt).format('LT');
    }, [createdAt]);


    const directId = useMemo(() => {
        const direct = chatState.directs
            .find(direct =>
                direct?.members?.length === 2 &&
                direct?.members?.find?.(member => member?.user?._id === senderId?._id) &&
                direct?.members?.find?.(member => member?.user?._id === appState?.user?._id));

        return direct?._id;
    }, [message, chatState.directs]);

    const redirectToDirects = useCallback(() => {
        if (directId) {
            navigate(`/message/direct/${directId}`);
        }
        else {
            socket.emit('DIRECT_CREATE', {
                userIds: [senderId?._id, appState?.user?._id]
            }, (response) => {
                const directId = response?.data?._id;
                if (directId) {
                    navigate(`/message/direct/${directId}`);
                } else {
                    toast({
                        title: 'Error',
                        description: "We are having problems creating a direct message.",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            });
        }
    }
        , [directId, socket, senderId, appState?.user?._id, navigate, toast]);

    const isUserOnline = useMemo(() => {
        return onlineUsers[senderId?._id] || false;
    }, [onlineUsers, senderId]);

    return (
        <Box className="test" pos={'relative'} mt={!isPreviousMessageIsMine ? 3 : 0} maxW={'100%'} overflow={'hidden'} flex={'1 1 auto'}>
            {type === "CALL" &&
                <CallStatusMessage
                    key={_id}
                    _id={_id}
                    messageTime={messageTime}
                    callStatus={callStatus}
                    roomId={roomId}
                    duration={duration}
                />
            }
            <Popover placement='top' trigger="hover" isLazy>
                <Flex maxWidth={{ base: '100%', md: "60%" }} width="fit-content">
                    <Flex mt="5px" flexDir={'row'} gap="15px">
                        <>
                            <PopoverTrigger>
                                {
                                    isPreviousMessageIsMine ?
                                        <Box h={45} w={45} visibility={'hidden'} />
                                        :
                                        <Box>
                                            <Avatar height="45px" width="45px" name={senderId.name} src={senderId.profilePicture}>
                                                <AvatarBadge boxSize='1em' bg={isUserOnline ? 'green.500' : 'gray.500'} />
                                            </Avatar>
                                        </Box>
                                }
                            </PopoverTrigger>
                            <Box>
                                {
                                    !isPreviousMessageIsMine &&
                                    <Flex alignItems="center" gap="10px" flexDir={'row'} >
                                        <Text fontSize="fs.14" textAlign="end" fontWeight="fw.600">{senderId.name}</Text>
                                        <Text fontSize="fs.12" textAlign="start" fontWeight="fw.400" color="#868B94">{messageTime}</Text>
                                    </Flex>
                                }
                                {
                                    text &&
                                    <Flex className="message-preview" w={'fit-content'} flexDir={'column'} gap="6px" py="10px" px="15px" background="gray.100" borderRadius="0px 12px 12px 12px">
                                        <HTMLComponent htmlContent={text} />
                                    </Flex>
                                }
                                {
                                    Array.isArray(fileURL) &&
                                    fileURL.map(file =>
                                        <Flex className="~~~~~~~~~~" my={1} w={'fit-content'} flexDir={'column'} gap="6px" py="10px" px="15px" background="gray.100" borderRadius="0px 12px 12px 12px">
                                            {console.log(' ~~~~~~~~~~~ Comes here ')}
                                            <Box key={file} my={1}>
                                                <MessageFileItemPreview file={file} openDocViewer={() => setIsDocViewerOpen(true)} />
                                            </Box>
                                        </Flex>
                                    )
                                }
                            </Box>
                        </>
                    </Flex>
                </Flex>
                <PopoverContent flexDir={'column'} gap={'10px'} p={4} w={'fit-content'} borderRadius={'10px'} boxShadow={'0 0 5px 2px rgba(0, 0, 0, 0.2)'} ml={'10px'}>
                    <Flex flexDir={'row'} align={'center'} columnGap={'10px'}>
                        <Avatar height="45px" width="45px" name={senderId.name} src={senderId.profilePicture} />
                        <Box>
                            <Text isTruncated title={senderId?.name} fontWeight={'500'}>{senderId?.name}</Text>
                            <Text fontSize={'12'}>({senderId?.role})</Text>
                        </Box>
                    </Flex>
                    {
                        !isMe &&
                        <Button onClick={redirectToDirects} variant={'outlined'} minW={'300px'} border={'1px solid black'} borderRadius={'7px'} display={'flex'} columnGap={'10px'}>
                            <FontAwesomeIcon icon={faCommentAlt} />
                            <Text fontWeight={'500'}>Message</Text>
                        </Button>
                    }
                </PopoverContent>
            </Popover>
            {
                Array.isArray(fileURL) &&
                <Modal isOpen={isDocViewerOpen} size={'full'}>
                    <ModalOverlay>
                        <ModalContent background={"none"}>
                            <ModalBody>
                                <Box display={'flex'}
                                    justifyContent={'end'}
                                    alignItems={'center'}
                                >
                                    {/* <Button
                                        bg={'blackAlpha.600'}
                                        variant={'unstyled'}
                                        p={2}
                                        borderRadius={'100%'}
                                        display={'flex'}
                                        justifyContent={'end'}
                                        alignItems={'center'}
                                        color={'white'}
                                        onClick={() => setIsDocViewerOpen(false)}
                                    >
                                        <IconX />
                                    </Button> */}
                                </Box>
                                <FileViewer urls={fileURL} setIsDocViewerOpen={setIsDocViewerOpen} />
                                {/* <DocViewer
                                    documents={fileURL}
                                    pluginRenderers={[DocViewerRenderers]}
                                    activeDocument={activeDoc}
                                    onDocumentChange={setActiveDoc}
                                /> */}
                            </ModalBody>
                        </ModalContent>
                    </ModalOverlay>
                </Modal>
            }
        </Box >
    );
}, (pp, np) => _.isEqual(pp, np));
