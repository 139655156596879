import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { IconEye, IconEyeClosed } from '@tabler/icons-react';
import React, { useState } from 'react';


const PasswordField = ({ _focusVisible, _hover, disabled, onFocus, onBlur, autoComplete, mb, register, placeholder }) => {

  console.log(register);

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(prev => !prev);
  };
  return (
    <InputGroup size={'lg'}>
      <Input
        {...register}
        border={_focusVisible ? "1px solid rgba(210, 210, 210, 0.5)" : "1.5px solid rgba(210, 210, 210, 0.5)"}
        type={showPassword ? "text" : "password"}
        focusBorderColor="#713EDD"
        _focusVisible={_focusVisible}
        placeholder={placeholder}
        mb={mb}
        autoComplete={autoComplete}
        onFocus={onFocus}
        onBlur={onBlur}
        isDisabled={disabled}
        _hover={_hover}
        fontSize={{ base: 'fs.12', md: 'fs.14', ld: 'fs.16', xl: 'fs.18', }}
        fontWeight="fw.400"
        borderRadius="10px"
        height={{ base: '40px', md: '50px', '2xl': '55px' }}
      >
      </Input>
      <InputRightElement mt={1} onClick={togglePassword}>
        <IconButton
          aria-label='Hide/Show password'
          bg='white'
          _hover={{ bg: 'gray.100' }}
          color={'gray.500'}
          icon={showPassword ?
            <IconEye />
            :
            <IconEyeClosed />}
        />
      </InputRightElement>
    </InputGroup >
  );
};

export default PasswordField;