import { MeetingProvider } from "@videosdk.live/react-sdk";
import { useContext, useEffect, useState } from "react";
import { MeetingAppProvider } from "../../contexts/MeetingAppContextDef";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { LeaveScreen } from "./components/screen/LeaveScreen";
import { AppContext } from "../../contexts/AppContext";
import { useSocket } from "../Socket";
import { useNavigate, useParams } from "react-router-dom";
import CallNotification from "./components/CallNotification";
import { useSelector } from 'react-redux';

function VideoCall() {
  const [appState] = useContext(AppContext);
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState(appState?.user.name || "TestUser");
  const [micOn, setMicOn] = useState(false);
  const [webcamOn, setWebcamOn] = useState(false);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [_, setIsMeetingLeft] = useState(false);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;
  const params = useParams();
  const { meetingID } = params;
  const { socket, callCreatedData, clearCallCreatedData } = useSocket();
  const [stateApp] = useContext(AppContext);
  const { user } = stateApp;
  const [isIncoming, setIsIncoming] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const isToken = useSelector((state) => state.token.token);
  const [isLeave, setIsLeave] = useState(true);
  const [goBack, setGoBack] = useState(true);



  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (meetingID) {
      setMeetingId(meetingID);
      setMeetingStarted(true);
      setToken(isToken);
    }
  }, [meetingID]);

  useEffect(() => {
    if (callCreatedData?.roomId && callCreatedData?.callOwnerDetail?._id !== user._id && callCreatedData?.roomId !== meetingID) {
      setIsIncoming(true);
    }
  }, [callCreatedData]);

  useEffect(() => {
    if (isLeave) {
      const handlePopState = () => {
        if (window.confirm("Are you sure you want to navigate back?")) {
          navigate(-1);
        } else {
          window.history.pushState(null, null, window.location.pathname);
        }
      };

      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
    else {
      navigate(-1);
    }
  }, [isLeave, navigate]);

  const onReject = () => {
    setIsIncoming(false);
    clearCallCreatedData();
    { callCreatedData?.type === "Directs" && socket.emit('CALL_REJECT', { type: callCreatedData.type, roomId: callCreatedData.roomId }); }
  };


  const handleVideoCall = async () => {
    if (isMeetingStarted) {
      setToken("");
      setMeetingId("");
      setMeetingStarted(false);
      setGoBack(false);
      setIsMeetingLeft(true);
    }

    if (callCreatedData?.roomId) {
      setMeetingStarted(true);
      setMeetingId(callCreatedData?.roomId);
      setMicOn(true);
      setToken(isToken);
      setIsLeave(true);
      setIsMeetingLeft(false);
      setParticipantName(appState?.user.name);
      navigate(`/call/${callCreatedData.roomId}`);
    }
    setIsIncoming(false);
    clearCallCreatedData();
  };

  return (
    <>
      <MeetingAppProvider>
        {isMeetingStarted ? (
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",
              multiStream: true,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                setToken("");
                setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
                setIsLeave(false);
                if (goBack) {
                  setTimeout(() => {
                    navigate(-1);
                  }, 0);
                }
              }}
              setIsMeetingLeft={setIsMeetingLeft}
            />
          </MeetingProvider>
        ) : (
          <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
        )}

        {isIncoming && <CallNotification isIncoming={isIncoming} onReceive={handleVideoCall} onReject={onReject} />}
      </MeetingAppProvider>
    </>
  );
}

export default VideoCall;
